export const provinces = [
	'北京市',
	'河北省',
	'内蒙古自治区',
	'吉林省',
	'上海市',
	'浙江省',
	'福建省',
	'山东省',
	'湖北省',
	'广东省',
	'海南省',
	'四川省',
	'云南省',
	'陕西省',
	'青海省',
	'新疆维吾尔自治区',
	'澳门特别行政区',
	'天津市',
	'山西省',
	'辽宁省',
	'黑龙江省',
	'江苏省',
	'安徽省',
	'江西省',
	'河南省',
	'湖南省',
	'广西壮族自治区',
	'重庆市',
	'贵州省',
	'西藏自治区',
	'甘肃省',
	'宁夏回族自治区',
	'香港特别行政区',
	'台湾省',
];

