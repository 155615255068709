<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">代客下单</div>
		<div class="mt20">
			<div class="bg-w pd30" style="min-height: 800px;">
				<div >
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						<a-spin :spinning="showMemberLoading">
							<a-form-model-item required label="选择用户" v-if="JSON.stringify(form.member) == '{}'">
								<div class="alcenter flex">
									<a-input-search v-model="form.mobile" placeholder="请输入用户手机号"  style="width: 160px" @search="onSearch" />
									<div class="ml10 flex alcenter ft12 ftw400 cl-notice" v-if="msgStatus">
										<div>暂无该用户，请重新输入或 </div>
										<div class="cl-theme clickAct" @click="addMemberAct()">添加会员</div>
									</div>
								</div>
							</a-form-model-item>
							
							<a-form-model-item  label="选中的用户" v-if="JSON.stringify(form.member) != '{}'">
								<div class="flex alcenter ml10">
									<div class="flex alcenter">
										<div class="ft14 ftw400 cl-main">昵称：</div>
										<div class="ft14 ftw400 cl-black ml5">{{form.member.nick_name}}</div>
									</div>
									
									<div class="flex alcenter ml20">
										<div class="ft14 ftw400 cl-main">手机号：</div>
										<div class="ft14 ftw400 cl-black ml5">{{form.member.mobile}}</div>
									</div>
									
									<div class=" ml20">
										<a-button type="primary" @click="reChooseAct()">重新选择</a-button>
									</div>
								</div>
							</a-form-model-item>
						</a-spin>
						<a-form-model-item required label="配送方式"  v-if="selectType != 'store'">
							<a-radio-group v-model="form.logistics_type">
								<a-radio :value="1">快递配送</a-radio>
								<a-radio :value="2">到店自提</a-radio>
							</a-radio-group>
						</a-form-model-item>
						
						<a-form-model-item required label="收货地址" v-if="form.logistics_type==1 && selectType != 'store'">
							<div>
								<a-input v-model="form.receiving_name"  style="width: 160px;" placeholder="请输入收件人"></a-input>
								<a-input v-model="form.receiving_mobile"  style="width: 160px;" class="ml10" placeholder="请输入收件号码"></a-input>
							</div>
							<div>
								<a-select v-model="form.receiving_address_province" style="width: 160px;" @change="changeProvince">
									<template v-for="(item,index) in provinces">
										<a-select-option :value="item" >{{item}}</a-select-option>
									</template>
								</a-select>
								<a-input v-model="form.receiving_address_city" placeholder="请输入收件城市"  style="width: 160px;" class="ml10"></a-input>
								<a-input v-model="form.receiving_address_county" placeholder="请输入收件城镇"  style="width: 160px;" class="ml10"></a-input>
								
							</div>
							<div>
								<a-input v-model="form.receiving_address_info" placeholder="请输入具体地址"  style="width: 480px;" class=""></a-input>
							</div>
						</a-form-model-item>
						
						<a-form-model-item required label="选择商品" >
							<a-spin :spinning="showGoodsLoading">
								<div class="flex alcenter wrap">
									<template v-for="(item,index) in chooseType">
										<div class="choose-goods-btn active" @click="chooseTypeAct(index)" v-if="item.iden==selectType">{{item.name}}</div>
										<div class="choose-goods-btn" v-else @click="chooseTypeAct(index)">{{item.name}}</div>
									</template>
								</div>
								<div class="mt24" v-if="goods.length>0">
									<div class="show-goods">
										<div class="show-goods-item" v-for="(item,index) in goods">
											<div class="flex space alcenter">
												<div class="flex alcenter" style="width: 70%;" v-if="selectType=='normal'">
													<img v-if="item.cover_img != null" :src="item.cover_img"/>
													<div class="ml10" style="width: 40%;">
														<div  class="text-over">
															{{item.name}} 
														</div>
														<div class="cl-notice text-over"  v-if="item.is_have_sku==1" >{{item.sku_name}}</div>
													</div>
													
													<div style="margin-left: 80px; width: 20%;">
														<div class="flex alcenter">
															<div v-if="JSON.stringify(form.member) != '{}' && form.member.level_id != 0">¥{{ item.is_have_sku==1 ? item.sku_vip_price : item.vip_price}}</div>
															<div v-else>¥{{ item.is_have_sku==1 ?  item.sku_original_price : item.original_price}}</div>
															<div class="ml8">x{{item.num}}</div>
														</div>
													</div>
												</div>
												
												<div class="flex alcenter"  style="width: 70%;" v-if="selectType=='pre_sale'">
													<img v-if="item.cover_img != null" :src="item.cover_img"/>
													<div class="ml10" style="width: 40%;">
														<div  class="text-over">
															{{item.name}} 
														</div>
														
														<div class="cl-notice text-over"  v-if="item.is_have_sku==1" >{{item.sku_name}}</div>
													</div>
													
													<div style="margin-left: 80px; width: 20%;">
														<div class="flex alcenter">
															<div >¥{{ item.is_have_sku==1 ? item.sku_yu_price : item.yu_price}}</div>
															<div class="ml8">x{{item.num}}</div>
														</div>
													</div>
												</div>
												
												<div class="flex alcenter"  style="width: 70%;" v-if="selectType=='store'">
													<img v-if="item.goods.cover_img != null" :src="item.goods.cover_img"/>
													<div class="ml10" style="width: 40%;">
														<div  class="text-over">
															{{item.goods.name}} 
														</div>
													</div>
													
													<div style="margin-left: 80px; width: 20%;">
														<div class="flex alcenter">
															<div >¥{{item.total_price}}</div>
															<div class="ml8">x1</div>
														</div>
													</div>
												</div>
												
												<div >
													<i class="iconfont iconbtn_close ft20 cl-info clickAct" @click="delGoodsAct(index)"/>
												</div>
											</div>
										</div>
									</div>
									<div class="mt16">
										<div class="flex alcenter">
											<div class="flex alcenter">
												<div class="ft12 ftw400 cl-info">合计金额：</div>
												<div class="ft12 ftw400 cl-black">¥{{getPrice}}</div>
											</div>
											<div class="flex alcenter ml20" v-if="this.selectType!= 'store'">
												<div class="ft12 ftw400 cl-info">运费：</div>
												<div class="ft12 ftw400 cl-black">¥{{form.freight}}</div>
											</div>
										</div>
									</div>
									
									<div class="mt16">
										<div class="flex alcenter">
											<div class="ft12 ftw400 cl-info">实付金额</div>
											<div class="ml20">
												<a-input-number v-model="form.need_pay"  placeholder="请输入" :precision="2" :min="0" style="width: 120px;"/>
											</div>
										</div>
									</div>
								</div>
							</a-spin>
						</a-form-model-item>
						
						
						
						
						<a-form-model-item required label="订单状态" >
							<a-radio-group v-model="form.status" v-if="form.logistics_type==1 && selectType != 'store'">
								<a-radio :value="2">待发货</a-radio>
								<a-radio :value="8">已完成</a-radio>
							</a-radio-group>
							
							<a-radio-group v-model="form.status" v-if="form.logistics_type==2 || selectType=='store'">
								<a-radio :value="4">待自提</a-radio>
								<a-radio :value="8">已完成</a-radio>
							</a-radio-group>
						</a-form-model-item>
						
						<a-form-model-item required label="是否算合伙人业绩" >
							 <a-switch v-model="form.is_commission==1" @change="changeSwitch" />
						</a-form-model-item>
						
						
						
						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }" > 
						      <a-button type="primary" @click="onSubmit" :loading="confirmLoading">
						        确认下单
						      </a-button>
						</a-form-model-item>
					</a-form-model>	
					
				</div>
			</div>
		</div>
		
		<div v-if="chooseGoodsLoading">
			<choose-goods :visible="chooseGoodsLoading" v-model="form.goods" :type="selectType" @cancel="cancelChooseGoods" @ok="okChooseGoods"></choose-goods>
		</div>
		
		<div v-if="addMemberVisible">
			<add-member v-model="form.member" :visible="addMemberVisible" @cancel="cancelAddMember" @ok="okAddMember"></add-member>
		</div>
	</div>
</template>

<script>
	import {provinces} from '../../common/router/province.js';
	import chooseGoods from './components/CustomerToOrder/modal/chooseGoods.vue';
	import addMember from './components/CustomerToOrder/modal/addMember.vue';
	export default{
		components:{
			chooseGoods,
			addMember,
		},
		data(){
			return{
				loading:false,
				showMemberLoading:false,
				showGoodsLoading:false,
				chooseGoodsLoading:false,
				addMemberVisible:false,
				confirmLoading:false,
				labelCol: { span: 4 },
				wrapperCol: { span: 20 },
				msgStatus:false,
				provinces:[],
				selectType:'',
				chooseType:[
					{iden:'normal',name:'普通商品'},
					{iden:'pre_sale',name:'预售商品'},
					{iden:'store',name:'囤货商品'}
				],
				goods:[],
				form:{
					mobile:'',
					member:{},
					logistics_type:1,
					receiving_mobile:'',
					receiving_name:'',
					receiving_address_province:'',
					receiving_address_city:'',
					receiving_address_county:'',
					receiving_address_info:'',
					freight:0,
					goods:[],
					need_pay:'',
					status:0,
					is_commission:0,
				}
			}
		},
		created() {
			this.provinces=provinces;
		},
		
		computed:{
			getPrice(){
				let price = 0;
				if(this.goods.length<=0){
					return price;
				}
				for(var i in this.goods){
					if(this.selectType=='store'){
						let goods_price = this.goods[i].total_price;
						price += goods_price*100;
					}
					if(this.selectType=='normal'){
						let goods_price=0;
						if(JSON.stringify(this.form.member) != '{}' && this.form.member.level_id != 0){
							 goods_price= this.goods[i].is_have_sku==1 ? this.goods[i].sku_vip_price : this.goods[i].vip_price;
						}else{
							goods_price = this.goods[i].is_have_sku==1 ? this.goods[i].sku_original_price : this.goods[i].original_price;
						}
						price += goods_price * this.goods[i].num *100;
					}
					if(this.selectType=='pre_sale'){
						let goods_price = this.goods[i].is_have_sku==1 ? this.goods[i].sku_yu_price : this.goods[i].yu_price;
						price += goods_price * this.goods[i].num *100;
					}
				}
				
				return price/100;
			},
			
		},
		
		methods:{
			
			chooseTypeAct(index){
				if(this.selectType!=this.chooseType[index].iden){
					this.selectType=this.chooseType[index].iden;
					this.goods=new Array;
					this.form.goods=new Array;
				}
				this.chooseGoodsAct();
			},
			
			changeProvince(){
				if(this.form.receiving_address_province == '') return; 
				if(this.form.goods.length<=0) return;
				if(this.selectType=='store') return;
				let num=0;
				for(var i in this.form.goods){
					num += this.form.goods[i].num;
				}
				this.$http.api('admin/getFreightFromFakeOrder',{
					num:num,
					province:this.form.receiving_address_province,
				}).then(res=>{
					this.form.freight = res.freight;
				}).catch(res=>{
					console.log(res);
				});
				
			},
			
			onSearch(){
				if(this.showMemberLoading==true) return;
				this.showMemberLoading=true;
				this.$http.api('admin/getMemberWithMobileFromFakeOrder',{
					mobile:this.form.mobile,
				}).then(res=>{
					this.msgStatus=false;
					this.form.member=res.member;
					this.showMemberLoading=false;
				}).catch(res=>{
					this.showMemberLoading=false;
					this.msgStatus=true;
				})
				
			},
			
			reChooseAct(){
				this.msgStatus=false;
				this.form.member=new Object;
			},
			
			chooseGoodsAct(){
				this.chooseGoodsLoading=true;
			},
			cancelChooseGoods(){
				this.chooseGoodsLoading=false;
			},
			okChooseGoods(){
				this.chooseGoodsLoading=false;
				if(this.form.goods.length == 0) return; //如果数据为空不请求
				let goods_ids = new Array;
				for(var a in this.form.goods){
					goods_ids.push(this.form.goods[a].goods_id);
				}
				if(this.showGoodsLoading==true) return;
				this.showGoodsLoading=true;
				this.$http.api('admin/getGoodsByIdsFromFakeOrder',{
					goods_ids:goods_ids,
					type:this.selectType,
					limit:this.form.goods.length,
					page:1,
				}).then(res=>{
					this.goods = res.list;
					this.GoodsInit();
					this.changeProvince();
					this.showGoodsLoading=false;
				}).catch(res=>{
					console.log(res);
					this.showGoodsLoading=false;
				});
			},
			
			GoodsInit(){
				let goods =JSON.parse(JSON.stringify(this.goods));
				console.log(goods);
				for(var i in goods){
					for(var j in this.form.goods){
						let status = ((this.selectType=='store' && goods[i].store_goods_id==this.form.goods[j].goods_id) || (this.selectType!='store' && goods[i].goods_id==this.form.goods[j].goods_id))
						if(status){
							goods[i].is_select=1;
							goods[i].select_index=j;
							goods[i].num=this.form.goods[j].num;
							goods[i].sku_id=this.form.goods[j].sku_id;
							if(this.form.goods[j].sku_id != 0){
								for( var k in goods[i].sku){
									if(this.form.goods[j].sku_id==goods[i].sku[k].sku_id){
										goods[i].sku_name= goods[i].sku[k].name;
										goods[i].sku_original_price= goods[i].sku[k].original_price;
										goods[i].sku_vip_price= goods[i].sku[k].vip_price;
										goods[i].sku_yu_price= goods[i].sku[k].yu_price;
									}
								}
							}
						}
					}
				}
				console.log(goods);
				this.goods=goods;
			},
			
			delGoodsAct(index){
				let select_index=this.goods[index].select_index;
				this.goods.splice(index,1);
				this.form.goods.splice(select_index,1);
				console.log(this.goods);
				console.log(this.form.goods);
			},
			
			addMemberAct(){
				this.addMemberVisible=true;
			},
			cancelAddMember(){
				this.addMemberVisible=false;
			},
			okAddMember(){
				this.msgStatus=false;
				this.addMemberVisible=false;
			},
			
			changeSwitch(){
				this.form.is_commission=this.form.is_commission == 1 ? 0 :1;
			},
			
			onSubmit(){
				if(this.selectType == ''){
					this.$message.error('请选择商品');
					return;
				}
				
				if(JSON.stringify(this.form.member) == '{}'){
					this.$message.error('请选择会员');
					return;
				}
				
				if(this.form.goods.length<=0){
					this.$message.error('请选择商品');
					return;
				}
				
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/createFakeOrder',{
					type:this.selectType,
					member_id:this.form.member.member_id,
					logistics_type:this.form.logistics_type,
					receiving_name:this.form.receiving_name,
					receiving_mobile:this.form.receiving_mobile,
					receiving_address_province:this.form.receiving_address_province,
					receiving_address_city:this.form.receiving_address_city,
					receiving_address_county:this.form.receiving_address_county,
					receiving_address_info:this.form.receiving_address_info,
					total_price:this.getPrice,
					need_pay:this.form.need_pay,
					goods:this.form.goods,
					status:this.form.status,
					is_commission:this.form.is_commission,
				}).then(res=>{
					this.$message.success('下单成功',1,()=>{
						this.confirmLoading=false;
						if(this.selectType=='store'){
							this.$router.push('/mall/store');
						}else{
							this.$router.push('/mall/order');
						}
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			}
		}
	}
</script>

<style>
	.show-goods{
		width: 100%;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		border-bottom: none;
	}
	
	.show-goods-item{
		padding: 20px;
		width: 100%;
		border-bottom: 1px solid #EBEDF5;
	}
	
	.show-goods-item-tag{
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;
		
		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}
	
	.show-goods-item img{
		width: 60px;
		height: 60px;
	}
	
	.choose-goods-btn{
		padding: 8px 12px;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		
		font-size: 12px;
		font-weight: 500;
		color: #232529;
		line-height: 20px;
		margin-right: 15px;
		cursor: pointer;
	}
	
	.choose-goods-btn.active{
		border: 1px solid #4772FF;
		color: #4772FF;
	}
	
</style>
